.header {
  height: 85vh;

  background-image: linear-gradient(
      to right bottom,
      rgba(#9cecfb, 0.8),
      rgba(#65c7f7, 0.8),
      rgba(#0052d4, 0.8)
    ),
    url();
  background-size: cover;
  background-position: top;
  position: relative;

  @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    height: 95vh;
  }

  @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
    only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
    only screen and (min-width: 125em) {
    background-image: linear-gradient(
        to right bottom,
        rgba(#9cecfb, 0.8),
        rgba(#65c7f7, 0.8),
        rgba(#0052d4, 0.8)
      ),
      url();
  }

  @include respond(phone) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
  }

  &__logo-box {
    position: absolute;
    top: 4rem;
    left: 4rem;
  }

  &__logo {
    height: 8.5rem;
  }

  &__text-box {
    position: absolute;
    top: 35%;
    left: 10%;
    max-width: 780px;
    // transform: translate(-50%, -50%);
    font-family: 'Karla';
    // text-align: center;

    // @media only screen and (max-width: $bp-largest) {
    //   top: 50%;
    //   left: 30%;
    // }

    // @media only screen and (max-width: $bp-medium) {
    //   top: 50%;
    //   left: 50%;
    // }
    // @media only screen and (max-width: $bp-small) {
    //   bottom: 10%;
    //   left: 10%;
    //   transform: translate(0%, 0%);
    // }

    &__main {
      font-size: 4.5rem;
      color: white;

      @media only screen and (max-width: $bp-largest) {
        font-size: 4.5rem;
      }

      @media only screen and (max-width: $bp-small) {
        font-size: 4.5rem;
      }
    }
  }

  &__box {
    // width: 40%;
    // height: 40%;
    //  padding: 30px 50px;
    // background-color: rgba(0, 0, 0, 0.548);
    color: white;
    font-weight: bold;
    box-sizing: $shadow-dark;
    border-radius: 20px;
    margin-top: -10px;

    & > * {
      margin: 20px;

      @media only screen and (max-width: $bp-largest) {
        margin: 20px 0;
      }
    }

    &__title {
      font-size: 3.5rem;

      @media only screen and (max-width: $bp-medium) {
        font-size: 25px;
      }

      @media only screen and (max-width: $bp-small) {
        font-size: 25px;
        margin-top: 50px;
      }
    }

    &__sub {
      font-size: 30px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    &__buttons {
      display: flex;
      // justify-content: flex-start;
      margin-top: 30px;

      & > * {
        padding: 10px 15px;
        // background-color: rgba(0, 0, 0, 0.664);
        font-size: 20px;
        border-radius: 20px;
        /// width: 40%;

        display: flex;
        justify-content: center;
        transition: all 0.4s;
        color: white;
        cursor: pointer;

        &:hover {
          color: black;
          background-color: white;
        }

        @media only screen and (max-width: $bp-medium) {
          font-size: 16px;
          padding: 15px 20px;
        }
      }

      &__column {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
      }

      &__first {
        width: 90px;
        height: 90px;

        @media only screen and (max-width: $bp-small) {
          width: 60px;
          height: 60px;
        }
      }
      &__second {
        width: 90px;
        height: 90px;

        @media only screen and (max-width: $bp-small) {
          width: 60px;
          height: 60px;
        }
      }

      &__text {
        display: flex;
        justify-content: center;
        font-size: 25px;

        @media only screen and (max-width: $bp-small) {
          font-size: 20px;
        }
      }
    }
  }
}
