.u-center-text {
  text-align: center !important;
}

.u-margin-right-small {
  margin-right: 1.5rem !important;
}

.u-margin-right-big {
  margin-right: 4rem !important;
}

.text-align-center {
  text-align: center;
}

.big-font-size {
  font-size: 4rem;
}

.medium-font-size {
  font-size: 2rem;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem !important;

  @include respond(tab-port) {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;

  @include respond(tab-port) {
    margin-bottom: 5rem !important;
  }
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-small {
  margin-top: 4rem !important;
}
.u-margin-top-huge {
  margin-top: 10rem !important;
}
