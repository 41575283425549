// COLORS
$color-primary: #2998ff;
$color-primary-light: #2998ff;
$color-primary-dark: #5643fa;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

// FONT
$default-font-size: 16px;

.big-font {
  font-size: 7rem;
}

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

// $color-primary: #55c57a;
// $color-primary-light: #7ed56f;
// $color-primary-dark: #28b485;

/////////// BREAK POINTS //////////
$bp-ultraWide: 350em; // 1200px
$bp-largest: 100em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-750: 46.875em; // 750px
$bp-550: 34.375em; // 550px
$bp-420: 26.25em; // 420px
$bp-phoneLandscape: 47em;
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

// BORDER

$border: 1px solid #f4f2f2;
$border-dark: 2px solid #f4f2f2;

$shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
$shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.1);
