.thankyou {
  display: flex;
  height: 100vh;
  background-image: linear-gradient(to top, $color-primary, $color-secondary-dark);

  &__box {
    color: white;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);

    &__description {
      font-size: 4.5rem;
      color: white;

      @media only screen and (max-width: $bp-small) {
        font-size: 3.5rem;
      }
    }

    &__sub {
      font-size: 2.2rem;
      margin-top: 4%;

      @media only screen and (max-width: $bp-small) {
        margin-top: 10%;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      padding: 1rem 0;
      background-color: $color-secondary-dark;
      color: white;
      font-size: 2rem;
      font-weight: bold;
      width: 200px;
      margin-top: 5%;
      cursor: pointer;
      align-items: center;
      position: fixed;
      top: 140%;
      left: 40%;
      transform: translate(-50%, -50%);
      transform: -webkit-translate(-50%, -50%);
      transform: -moz-translate(-50%, -50%);
      transform: -ms-translate(-50%, -50%);

      &:hover {
        color: $color-secondary-dark;
        background-color: white;
        transition: 0.5s;
      }
    }
  }
}
