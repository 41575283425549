.blog-card__container {
  color: $color-primary;
  max-width: 350px;
  line-height: 3.5rem;
  padding: 4rem;
  background-color: $color-grey-light-1;
  z-index: 500;
  position: relative;
  box-shadow: 0 30px 50px 0 rgba(1, 1, 1, 0.15);
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 50px;
  max-height: 500px;

  & p {
    margin-bottom: 20px;
  }

  .blog-image {
  }

  .blog-title {
    color: $color-primary;
  }

  .blog-text {
    color: black;
  }
}
