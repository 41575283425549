.section-about {
  background-color: $color-grey-light-1;
  padding: 25rem 0;
  margin-top: -20vh;

  @include respond(tab-port) {
    padding: 20rem 3rem;
    margin-top: -20vh;
    font-size: 2.6rem;
  }

  @include respond(tab-land) {
    padding: 25rem 3rem;
    margin-top: -20vh;
    font-size: 2.6rem;
  }
}

.section-features {
  padding: 20rem 0;
  background-image: linear-gradient(
      to right bottom,
      rgba($color-primary-light, 0.8),
      rgba($color-primary-dark, 0.8)
    ),
    url();
  background-size: cover;
  transform: skewY(-7deg);
  margin-top: -10rem;
  margin-bottom: 10rem;

  & > * {
    transform: skewY(7deg);
  }

  @include respond(tab-port) {
    padding: 10rem 0;
  }
}

.section-tours {
  background-color: $color-grey-light-1;
  padding: 25rem 0 15rem 0;
  margin-top: -10rem;

  @include respond(tab-port) {
    padding: 20rem 0 10rem 0;
  }
}
.section-stories {
  position: relative;
  padding: 15rem 0;
  margin-top: -18rem;

  @include respond(tab-port) {
    padding: 10rem 0;
    margin-top: -15rem;
  }
}

.section-book {
  padding: 15rem 0;

  background-image: linear-gradient(
      to right bottom,
      $color-secondary-light,
      $color-secondary-dark
    ),
    url();

  @include respond(tab-port) {
    padding: 10rem 0;
  }
}

.book {
  background-image: linear-gradient(
      105deg,
      rgba($color-white, 0.9) 0%,
      rgba($color-white, 0.9) 50%,
      transparent 50%
    ),
    url(/static/images/signup1.jpg);
  background-size: cover;
  border-radius: 3px;

  box-shadow: 0 1.5rem 4rem rgba($color-black, 0.2);

  @include respond(tab-land) {
    background-image: linear-gradient(
        105deg,
        rgba($color-white, 0.9) 0%,
        rgba($color-white, 0.9) 65%,
        transparent 65%
      ),
      url(/static/images/signup1.jpg);
    background-size: cover;
  }

  @include respond(tab-port) {
    background-image: linear-gradient(
        to right,
        rgba($color-white, 0.9) 0%,
        rgba($color-white, 0.9) 100%
      ),
      url(/static/images/signup1.jpg);
  }

  &__form {
    width: 50%;
    padding: 6rem;

    @include respond(tab-land) {
      width: 65%;
    }

    @include respond(tab-port) {
      width: 100%;
    }
  }
}

.signin {
  background-image: linear-gradient(
      105deg,
      rgba($color-white, 0.9) 0%,
      rgba($color-white, 0.9) 50%,
      transparent 50%
    ),
    url(/static/images/signin.png) !important;
  background-size: 120% !important;
}
