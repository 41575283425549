.elementor-1134
  .elementor-element.elementor-element-69a7332.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-69a7332
  .elementor-heading-title {
  font-size: 55px;
  line-height: 1.2em;
}
.elementor-1134
  .elementor-element.elementor-element-69a7332
  > .elementor-widget-container {
  margin: -20px 0px 0px 0px;
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-29891b2.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-29891b2
  .elementor-heading-title {
  font-size: 38px;
  line-height: 1em;
}
.elementor-1134
  .elementor-element.elementor-element-29891b2
  > .elementor-widget-container {
  margin: -19px 0px 0px 0px;
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-34b3fba
  > .elementor-widget-container {
  margin: -7px 0px 0px 0px;
}
.elementor-1134 .elementor-element.elementor-element-0b261b5 .eael-data-table {
  max-width: 34%;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  .eael-data-table
  thead
  tr
  th:first-child {
  border-radius: 30px 0px 0px 0px;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  .eael-data-table
  thead
  tr
  th:last-child {
  border-radius: 0px 30px 0px 0px;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  .eael-data-table
  thead
  tr
  th {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  table.dataTable
  thead
  .sorting:after {
  color: #fff;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  table.dataTable
  thead
  .sorting_asc:after {
  color: #fff;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  table.dataTable
  thead
  .sorting_desc:after {
  color: #fff;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  .eael-data-table
  thead
  tr
  th:hover {
  color: #fff;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  table.dataTable
  thead
  .sorting:after:hover {
  color: #fff;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  table.dataTable
  thead
  .sorting_asc:after:hover {
  color: #fff;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  table.dataTable
  thead
  .sorting_desc:after:hover {
  color: #fff;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  .eael-data-table
  thead
  tr
  th
  i {
  font-size: 20px;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  .eael-data-table
  thead
  tr
  th
  .data-table-header-svg-icon {
  height: 20px;
  width: 20px;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  .eael-data-table
  tbody
  > tr:nth-child(2n)
  td {
  color: #6d7882;
  background: #f2f2f2;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  .eael-data-table
  tbody
  > tr:nth-child(2n + 1)
  td {
  color: #6d7882;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  .eael-data-table
  tbody
  > tr:nth-child(2n + 1)
  td:hover {
  color: #6d7882;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  .eael-data-table-wrap
  table
  td
  a {
  color: #c15959;
}
.elementor-1134
  .elementor-element.elementor-element-0b261b5
  .eael-data-table-wrap
  table
  td
  a:hover {
  color: #6d7882;
}
.elementor-1134
  .elementor-element.elementor-element-1ae9d0a.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-1ae9d0a
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-1ae9d0a
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-7fcbb85.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-7fcbb85
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-7fcbb85
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-b030848.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-b030848
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-b030848
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-6f8f8d8.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-6f8f8d8
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-6f8f8d8
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-59ec591.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-59ec591
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-59ec591
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-6e59b6a.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-6e59b6a
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-6e59b6a
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-832e43e.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-832e43e
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-832e43e
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-8aadf55.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-8aadf55
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-8aadf55
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-b7f3f7c.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-b7f3f7c
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-b7f3f7c
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-a70c613.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-a70c613
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-a70c613
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-1646b10.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-1646b10
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-1646b10
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-557807c.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-557807c
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-557807c
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-99946cf.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-99946cf
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-99946cf
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-aa74909.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-aa74909
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-aa74909
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
.elementor-1134
  .elementor-element.elementor-element-06d7a0a.elementor-widget-heading
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.78);
}
.elementor-1134
  .elementor-element.elementor-element-06d7a0a
  .elementor-heading-title {
  font-size: 38px;
}
.elementor-1134
  .elementor-element.elementor-element-06d7a0a
  > .elementor-widget-container {
  background-color: rgba(0, 0, 0, 0.82);
}
@media (max-width: 767px) {
  .elementor-1134
    .elementor-element.elementor-element-46b1547
    .elementor-image
    img {
    width: 100%;
  }
  .elementor-1134
    .elementor-element.elementor-element-69a7332
    .elementor-heading-title {
    font-size: 31px;
  }
}
