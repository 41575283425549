.article {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  z-index: 50;
  position: relative;

  @include respond(tab-land) {
    font-size: 2.6rem;
  }
  @include respond(phone) {
    font-size: 1.6rem;
  }

  &__big-images {
    width: 40%;

    @include respond(tab-land) {
      width: 100%;
    }
  }

  p {
    margin-bottom: 30px;
  }

  &__header {
    text-transform: uppercase;
    display: block;
    position: relative;
    margin-top: 170px;

    @include respond(tab-port) {
      margin-top: 150px;
    }

    &--text {
      position: absolute;
      color: blanchedalmond;
      font-size: 9rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__featured-image {
    width: 100%;
    max-height: 60vh;
    object-fit: cover;
  }

  &__header-elements {
    margin-top: -10.9rem;
    color: #fff;
    text-align: center;

    @include respond(tab-port) {
      margin-top: -1.25rem;
    }
  }

  &__title {
    font-size: 7rem;

    @include respond(tab-port) {
      font-size: 3rem;
      line-height: 1.4;
      margin-bottom: 2rem;
    }
  }

  &__highlight {
    padding: 0rem 1rem;
    background-color: $color-tertiary-light;

    &--german {
      padding: 0 20rem;
    }

    &--letter {
      background-color: greenyellow;
    }

    &--pronunciation {
      background-color: khaki;
    }
    &--example {
      background-color: lightpink;
    }

    &--black {
      background-color: #000;
      padding: 0 1rem;
    }

    &--skyblue {
      background-color: skyblue;
    }
  }

  &__meta {
    font-size: 1.8rem;
    margin-top: 3rem;

    @include respond(tab-port) {
      margin-top: -1rem;
      font-size: 1.8rem;
    }
  }

  &__blog-container {
    max-width: 1140px;
    line-height: 1.8rem;
    text-align: justify;
    margin: 0 auto;
    padding: 4rem;
    background-color: white;
    z-index: 500;
    position: relative;
    box-shadow: 0 30px 50px 0 rgba(1, 1, 1, 0.15);
    margin-bottom: 20px;

    table,
    th,
    td {
      border: 1px solid black;
    }

    &__bottom {
      border-top: solid 1px $color-grey-light-2;
      display: flex;
      padding-top: 100px;
      align-items: center;
      justify-content: space-around;

      @media only screen and (max-width: $bp-small) {
        flex-direction: column;
        margin: 20px auto;
      }

      &__left {
        display: flex;
        width: 40%;
        margin-right: 50px;

        @media only screen and (max-width: $bp-small) {
          width: 80%;
          justify-content: center;
        }
      }

      &__image {
        width: 100%;
        padding-right: 50px;
        border-right: solid 1px $color-grey-dark-2;

        @media only screen and (max-width: $bp-small) {
          border-right: none;
          margin-bottom: 50px;
        }
      }

      &__posts {
        display: flex;
        flex-direction: column;
        flex: 0 0 50%;
        line-height: 30px;

        & > * {
          margin: 10px 0;
        }

        &__title {
          color: black;
          font-size: 25px;

          &__item {
            color: blue;
          }
        }
      }
    }
  }

  &__body {
    line-height: 4rem;
  }

  &__category {
    display: inline-block;

    & a {
      text-decoration: none;
      color: white;
    }

    @include respond(tab-port) {
      margin-top: 1rem;
    }
  }

  &__tag {
    display: inline-block;
    margin-left: 3rem;

    & a {
      text-decoration: none;
      color: #fff;
    }
  }

  &__footer-articles {
    display: flex;
    max-width: 100vh;
  }

  &__spanish-banner {
    position: fixed;
    top: 80px;
    z-index: 200000;
    width: 100%;
    background-color: #151515;

    &__image {
      top: 80px;
      width: 978px;
      margin: 0 auto;
      height: 90px;
      background-image: url(../../assets/Spanish_Web.jpg);

      @include respond(tab-port) {
        width: 768px;
        height: 70px;
        background-image: url(../../assets/Spanish_Ipad.jpg);
      }
      @include respond(phone) {
        width: 320px;
        height: 70px;
        background-image: url(../../assets/Spanish_Mobile.jpg);
      }
    }
  }

  &__german-banner {
    position: fixed;
    z-index: 200000;
    top: 80px;
    width: 100%;
    background-color: #151515;

    &__image {
      width: 978px;
      margin: 0 auto;
      height: 90px;
      background-image: url(../../assets/German_Web.jpg);

      @include respond(tab-port) {
        width: 768px;
        height: 70px;
        background-image: url(../../assets/German_Ipad.jpg);
      }
      @include respond(phone) {
        width: 320px;
        height: 70px;
        background-image: url(../../assets/German_Mobile.jpg);
      }
    }
  }

  &__general-banner {
    position: fixed;
    z-index: 200000;
    top: 80px;
    width: 100%;
    background-color: #151515;

    &__image {
      width: 978px;
      margin: 0 auto;
      height: 90px;
      background-image: url(../../assets/General_Web.jpg);

      @include respond(tab-port) {
        width: 768px;
        height: 70px;
        background-image: url(../../assets/General_Ipad.jpg);
      }
      @include respond(phone) {
        width: 320px;
        height: 70px;
        background-image: url(../../assets/General_Phone.jpg);
      }
    }
  }
}
