.web {
  display: flex;
  
}

.webinar {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    margin-bottom: 100px;
    width: 1240px;
    margin: 0 auto;
    border-left: solid 10px $color-grey-light-2;
    border-right: solid 10px $color-grey-light-2;

    @media only screen and (max-width: $bp-small) {
      max-width: 100vw;
      border: none;
    }
    
    &__top {
       max-width: 1240px;
      height: 700px;
    background-image: linear-gradient(
      to right,
      rgba(#9cecfb, 0.8),
      rgba(#65c7f7, 0.8),
      rgba(#A0C586, 0.8)
    ),
    url('../../assets/webinar-image.jpg');
      color: black;
  
      background-size: cover;
    //  = background-position: top;
      position: relative;
      @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
        
      }

      @media only screen and (max-width: $bp-phoneLandscape) {
        width: 100%;
        height: 900px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }

      &__webinar {
          position: absolute;
          top: 20%;
          left: 10%;
          color: white;

          
          @media only screen and (max-width: $bp-large) {
            left: 5%;
           }

          @media only screen and (max-width: $bp-medium) {
            left: 5%;
            top: 10%;
           }

           @media only screen and (max-width: $bp-phoneLandscape) {
            left: 15%;
            top: 10%;
          }
          

           @media only screen and (max-width: $bp-small) {
            top: 5%;
           }

          &__name {
            font-size: 30px;
            text-decoration: underline;
          }

          &__heading {
           font-size: 35px;
            width: 70%;
            font-weight: bold;

            @media only screen and (max-width: $bp-large) {
              font-size: 28px;
              width: 60%;
            }

           

            

            @media only screen and (max-width: $bp-medium) {
              font-size: 28px;
              width: 50%;
            }

            @media only screen and (max-width: $bp-phoneLandscape) {
              font-size: 28px;
              width: 90%;
            }

           

            @media only screen and (max-width: $bp-small) {
              font-size: 20px;
              width: 90%;
            }
        }

        &__para {
            width: 50%;
            font-size: 18px;

            @media only screen and (max-width: $bp-phoneLandscape) {
              width: 90%;
            }

           
        }

        &__para-2 {
            width: 50%;
            font-size: 18px;
            margin-top: 40px;

            @media only screen and (max-width: $bp-phoneLandscape) {
              width: 90%;
             }
        }

       
          
      }

      &__form {
        position: absolute;
        right: 5%;
        top: 20%;
        width: 300px;

        @media only screen and (max-width: $bp-phoneLandscape) {
          top:60%;
          right: 10%;
          width: 80%;
          z-index: 100;
          
        }

        @media only screen and (max-width: $bp-small) {
          top:60%;
          right: 5%;
          width: 90%;
          z-index: 100;
         }
        

        &__top {
          background-color: $color-primary;
          color: white;
          font-size: 20px;
          display: flex;
          justify-content: center;
          padding: 10px 0;
        }

        &__body {
          background-color: white;
          padding: 20px;
          padding: 20px;
          display: flex;
          flex-direction: column;

          & > * {
            margin: 10px 0;
            border: solid 1px $color-grey-dark-2;
            padding: 5px;
            border-radius: 5px;
          }

         
        }
        &__button {
          display: flex;
          justify-content: center;
          background-color: #E05118;
          color: white;
          font-size: 20px;
          padding: 10px 0;
        }
      }

      
    }

    &__content {
      margin: 0 auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px;

        

        &__top {
          font-size: 20px;
          color: black;
          font-weight: bold;
          
        }

        &__body {
          font-size: 17px;
          color: $color-grey-dark-2;
          font-weight: bold;
          margin-top: 30px;
          margin-left: 40px;

          @media only screen and (max-width: $bp-small) {
            margin-left: 30px;
          }
          
        }
    }

    &__host {
      background-color: #F5F5F5;
     padding: 20px;
      display: flex;
      flex-direction: column;


      @media only screen and (max-width: $bp-small) {
        margin-bottom: 30px;
      }
      

      &__top {
        font-size: 30px;
        color: black;
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }

      &__content {
        max-width: 600px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        font-size: 16px;
        margin-top: 30px;

        @media only screen and (max-width: $bp-small) {
          padding: 0 20px;
        }
      }
    }
}