.section-book {
  padding: 15rem 0;

  background-image: linear-gradient(to right bottom, #f8fafb, #f8fafb),
    url(../../assets/signup1.jpg);

  @include respond(tab-port) {
    padding: 10rem 0;
  }
  border-bottom: solid 1px $color-grey-light-1;
}

.book {
  background-image: linear-gradient(
      105deg,
      rgba($color-white, 0.9) 0%,
      rgba($color-white, 0.9) 50%,
      transparent 50%
    ),
    url(../../assets/signup1.jpg);
  background-size: cover;
  border-radius: 3px;

  box-shadow: 0 1.5rem 4rem rgba($color-black, 0.2);

  @include respond(tab-land) {
    background-image: linear-gradient(
        105deg,
        rgba($color-white, 0.9) 0%,
        rgba($color-white, 0.9) 65%,
        transparent 65%
      ),
      url(../../assets/signup1.jpg);
    background-size: cover;
  }

  @include respond(tab-port) {
    background-image: linear-gradient(
        to right,
        rgba($color-white, 0.9) 0%,
        rgba($color-white, 0.9) 100%
      ),
      url(../../assets/signup1.jpg);
  }

  &__form {
    width: 50%;
    padding: 6rem;

    @include respond(tab-land) {
      width: 65%;
    }

    @include respond(tab-port) {
      width: 100%;
    }
  }
}

.form {
  &__group:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__input {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 1.5rem 2rem;
    border-radius: 2px;
    background-color: rgba($color-white, 0.5);
    border: none;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all 0.3s;

    &--border {
      border-bottom: 0 5px solid;
    }

    @include respond(tab-port) {
      width: 100%;
    }

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
      border-bottom: 3px solid $color-primary;
    }

    &:focus:invalid {
      border-bottom: 3px solid $color-secondary-dark;
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }
  }

  &__label {
    font-size: 1.4rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }

  &__radio-group {
    width: 49%;
    display: inline-block;

    @include respond(tab-port) {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  &__radio-input {
    display: none;
  }

  &__radio-label {
    font-size: $default-font-size;
    cursor: pointer;
    position: relative;
    padding-left: 4.5rem;
  }

  &__radio-button {
    height: 3rem;
    width: 3rem;
    border: 5px solid $color-primary;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -0.4rem;

    &::after {
      content: '';
      display: block;
      height: 1.3rem;
      width: 1.3rem;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $color-primary;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  &__radio-input:checked ~ &__radio-label &__radio-button::after {
    opacity: 1;
  }
}
