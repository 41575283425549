.nav-header {
  position: relative;
  top: 0;
  z-index: 10;
  font-size: 1.6rem;
  height: 80px;
  background-color: #fff;
  // border-bottom: 1px solid $color-grey-dark-3;
  color: #333;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  align-items: center;
  padding-right: 50px;
  // position: relative;

  // @media only screen and (max-width: 31.25em) {
  //   flex-wrap: wrap;
  //   align-content: space-around;
  //   height: 11rem;
  // }
}

.nav-logo {
  height: 6.25rem;
  margin-left: 2rem;
}

////////////////////// USER NAV ///////////////////////

.user-nav {
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 2%;
  color: inherit;

  & > * {
    padding: 0 1rem;
    cursor: pointer;
    height: 100%;
    display: flex;

    align-items: center;
    //  margin-bottom: 5px;
  }

  &__icon-box {
    position: relative;
    color: $color-grey-dark-2;

    &:hover {
      color: black;
      transition: 0.2s;
    }

    &__content {
      position: absolute;
      top: 50px;
      left: -20px;
      width: 100px;
      background-color: white;
      color: black;
      padding: 20px;
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      z-index: 10000;

      & > * {
        margin: 5px auto;
      }
    }

    &__content-course {
      position: absolute;
      top: 50px;
      left: -20px;
      width: 100px;
      background-color: white;
      color: black;
      padding: 20px;
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      z-index: 10000;

      & > * {
        margin: 5px auto;
      }
    }
  }

  &__icon {
    height: 2.25rem;
    width: 2.25rem;
    fill: $color-grey-dark-2;
  }

  &__user-photo {
    height: 4rem;
    border-radius: 50%;
    margin-right: 1rem;

    &:hover {
      border-bottom: hidden;
    }
  }
}

/////////////////////// search box ////////////////////////

.search {
  flex: 0 0 30%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 31.25em) {
    width: 100%;
    // order: 1;
    //flex: 0 0 100%;
    // background-color: $color-grey-light-2;
  }

  // @media only screen and (max-width: $bp-small) {
  //   flex: 0 0 100%;
  //   margin-top: -50px;
  // }

  &__input {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    background-color: $color-grey-light-2;
    border: none;
    padding: 0.7rem 2rem;
    border-radius: 100px;
    width: 90%;
    transition: all 0.2s;
    margin-right: -3.25rem;

    &:focus {
      outline: none;
      width: 100%;
      background-color: $color-grey-light-2;
    }

    &::-webkit-input-placeholder {
      font-weight: 300;
      color: $color-grey-dark-2;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $color-grey-dark-2;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: $color-grey-dark-2;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: $color-grey-dark-2;
    }
  }

  &__input:focus + &__button {
    background-color: $color-grey-light-2;
  }

  &__button {
    border: none;
    background-color: $color-grey-light-2;

    &:focus {
      outline: none;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  &__icon {
    height: 2rem;
    width: 2rem;
    fill: $color-grey-light-2;
  }
}

.nav-header__hidden {
  display: none;
}

.nav-header__progress {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background-color: #f1f1f1;
}

.nav-header__progress-container {
  width: 100%;
  height: 12px;
  background: #ccc;
}

.nav-header__progress-bar {
  height: 12px;
  background: $color-tertiary-light;
  width: 0%;
}

.nav-container {
  position: fixed;
  z-index: 10000000;
  display: flex;
  // flex-direction: column;
}
