.homeblog {
  display: flex;
  flex-direction: column;
  background-color: $color-grey-light-1;
  margin-top: -7%;
  margin-bottom: 10%;

  @include respond(tab-port) {
    margin-top: -13%;
  }

  @include respond(phone) {
    margin-top: -22%;
  }

  &__header {
    display: flex;
    justify-content: center;
    background-color: $color-grey-light-2;
    text-transform: uppercase;
    padding: 1rem;
    font-size: 3rem;
    font-weight: 400;
    margin: 0 auto;

    @include respond(tab-port) {
      font-size: 3rem;
    }

    @include respond(phone) {
      font-size: 2rem;
    }
  }

  &__body {
    margin-top: 5%;

    &__container {
      margin: 0 20%;
      display: flex;
      //  width: 900px;
      padding: 1rem;
      cursor: pointer;

      & > * {
        margin: 2rem 0;
      }

      @include respond(phone) {
        margin: 0;
      }

      &:hover {
        background-color: $color-grey-light-2;
      }

      &__image {
        height: 90px;
        width: 120px;

        @include respond(tab-port) {
          height: 130px;
        }
      }

      &__text {
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        justify-content: space-between;

        &__cat {
          background-color: $color-tertiary-light;
          color: white;
          font-size: 1.2rem;
          width: 80px;
          display: flex;
          justify-content: center;
          padding: 0.2rem 0.5rem;
        }

        &__title {
          text-transform: uppercase;
          color: black;
          font-size: 2rem;
          font-weight: bold;
          //   @include respond(tab-port) {
          //     font-size: 3rem;
          //   }

          @include respond(phone) {
            font-size: 2rem;
          }
        }

        &__sub {
          font-size: 1.6rem;
          text-transform: uppercase;
          color: $color-grey-dark-2;

          @include respond(phone) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  &__bottom {
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;

    &__blogs {
      width: calc(100% / 4);
      height: 250px;
      position: relative;

      @include respond(tab-port) {
        width: calc(100% / 2);
      }

      @include respond(phone) {
        width: 100%;
      }

      &__image {
        width: 100%;
        height: 100%;
      }

      &__title {
        position: absolute;
        bottom: 0;
        font-size: 1.4rem;
        color: white;
        padding: 1rem 1.5rem;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        background-image: linear-gradient(
          to right bottom,
          rgba($color-secondary-dark, 0.85),
          rgba($color-secondary-dark, 0.85)
        );

        @include respond(tab-port) {
          font-size: 2rem;
        }
      }
    }
  }
}

.internal {
  margin-top: 10%;
  display: flex;
  flex-direction: column;

  @include respond(tab-port) {
    margin-top: 15%;
  }

  @include respond(phone) {
    margin-top: 29%;
  }
}
