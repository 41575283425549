.week-player {
  outline: none;
  position: relative;
  display: flex;
  // align-items: center;
  justify-content: center;
  height: 100%;

  & .fas {
    color: #f7f8fa;
    font-size: 16px;
  }
}

.video-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  animation: spin 2s linear infinite;
  z-index: 100;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.video {
  width: 100%;
  height: 100%;
  position: relative;
}

.week-mouse-move .week-controls-container {
  opacity: 1;
  transition: all 0.2s ease-out;
}

.week-show-controls {
  width: 100%;
  height: 20%;
  z-index: 2;
  position: absolute;
  bottom: 0;
  cursor: default;
}

.week-controls-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  // margin-top: -95px;
  //box-sizing: border-box;
  z-index: 5;
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-out 2s;

  @media only screen and (max-width: $bp-ultraWide) {
    max-width: 1900px;
  }
}

.week-controls-container:not() .play-button {
  opacity: 0;
}

.play-button {
  padding: 15px 10px;
  border-radius: 50%;
  color: white;
  font-size: 40px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 15px;
}

.week-progress-range {
  display: flex;
  height: 12px;
  //width: calc(100% - 30px);
  //background-color: #f7f8fa;
  //margin: 0 auto;
  cursor: pointer;
  transition: height 0.1s ease-in-out;
  position: relative;
}

.week-progress-bar {
  background: #2fade4;
  cursor: pointer;
  height: 100%;
  transition: all 0.5s ease;
  z-index: 10;
}
