body {
  font-family: 'Karla';

  margin: 0 0;

  box-sizing: border-box;

  color: #777;
}

a {
  text-decoration: none;
  outline: none;
  color: inherit;
  /* color: #fff; */
}
