.category {
  font-size: 1.4rem;
  background-color: $color-grey-light-1;

  &__title {
    font-size: 7rem;
    display: block;
    text-align: center;
    margin-bottom: 6rem;
    color: white;
  }
}
