.class-container {
    max-width: 1440px;
    margin: 0 auto;

    &__top {
        background-color: #212735;
       // height: 70vh;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        padding: 60px;
        text-align: center;


        h1 {
            color: white;
            font-size: 30px;
        
        }

        h2 {
            font-size: 28px;
            max-width: 70%;
            margin-top: 20px;
            color: #F6BD00;
            font-weight: bold;
            text-align: center;


            @media only screen and (max-width: $bp-750) {
                font-size: 18px;
                max-width: 95%;
                
                }
        }

        h3 {
            font-size: 24px;
            color: white;
            margin-top: 30px;

            @media only screen and (max-width: $bp-750) {
                font-size: 18px;
                
                
                }
        }

        &__box {
            background-color: #F6BD00;
            padding: 10px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            border-radius: 5px;
            cursor: pointer;

            &__top {
            color: #212735;
            text-transform: uppercase;
            font-size: 30px;
            font-weight: bold; 

            @media only screen and (max-width: $bp-750) {
                font-size: 25px;
                text-align: center;
                }
            }

            &__bottom {
                font-size: 20px;
                color: black;

                @media only screen and (max-width: $bp-750) {
                    font-size: 18px;
                    text-align: center;
                    
                    }
            }
        }

        &__batch {
            font-size: 24px;
            margin-top: 30px;
            color: white;
            font-weight: bold;
            margin-bottom: 30px;
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 30px;

        &__heading {
            font-size: 30px;
            color: #201652;
            font-weight: bold;
            max-width: 60%;

            @media only screen and (max-width: $bp-750) {
                max-width: 90%;
                font-size: 24px;
                font-weight: bold;
                }
            
        }

        &__days {
            display: flex;
            flex-wrap: wrap;
            margin:  0 auto;
            justify-content: center;
            margin-top: 50px;

            & > * {
                background-color: #212735;
                padding: 15px;
                color: white;
                font-size: 24px;
                max-width: 500px;
                min-width: 300px;
                border-radius: 10px;
                margin: 20px auto;

                @media only screen and (max-width: $bp-750) {
                    min-width: 90%;
                    max-width: 95%;
                    }
                
            }
        }
    }
}

.workshop {
    display: flex;
    flex-direction: column;
     margin-top: 50px;
     align-items: center;
     justify-content: center;
     padding: 50px;
     background-color: #212735;

    &__title {
        color: white;
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 50px;
    }

    &__desc {
        font-size: 20px;
        text-align: center;
        max-width: 60%;
        color: #F6BD00;
        font-weight: bold;


        @media only screen and (max-width: $bp-750) {
           
            max-width: 95%;
            }
    }

   & ol {
       margin: 20px auto;
       max-width: 80%;
       
   }

   & span {
       margin-right: 10px;
   }

   & li {
       margin: 20px auto;
       font-size: 20px;
       color: white;
       list-style: none;
   }

}

.know {
    display: flex;
    flex-direction: column;
    margin: 30px 0;

    &__title {
       color: #201652;
       font-size: 50px;
       padding: 20px;
       font-weight: bold;
       margin-left: 100px;
       display: flex;
       justify-content: center;

       @media only screen and (max-width: $bp-750) {
       
        font-size: 30px;
        padding: 10px;
        margin-left: 0px;

        }
    }

    &__cards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        & > * {
            margin:  20px 50px;
        }

        &__card {
            display: flex;
            flex-direction: column;
            background-color: #212735;
            width: 500px;
            padding: 20px 50px;
            border-radius: 20px;

            @media only screen and (max-width: $bp-750) {
            align-items: center;
            padding: 10px;
        
                }

            &__top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__title {
                    font-size: 25px;
                    font-weight: bold;
                    color: #F6BD00;
                }

                &__image {
                    width: 100px;
                }
            }

            &__bottom {
                color: white;
                font-size: 20px;
                width: 80%;
            }
        }
    }
}