body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: $color-grey-dark;

  h1 {
    @include respond(phone) {
      font-size: 16px;
    }
  }
}

.heading-primary {
  color: $color-white;
  text-transform: uppercase;

  backface-visibility: hidden;
  margin-bottom: 1rem;
  margin-top: 10rem;

  &--main {
    display: block;
    font-size: 4rem;
    font-weight: 400;
    //letter-spacing: 2.5rem;
    font-weight: bold;

    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @media only screen and (max-width: $bp-largest) {
      font-size: 3rem;
    }

    @include respond(phone) {
      letter-spacing: 1rem;
      font-size: 5rem;
    }
    /*
      animation-delay: 3s;
      animation-iteration-count: 3;
      */
  }

  &--sub {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.75rem;
    animation: moveInRight 1s ease-out;

    @include respond(phone) {
      letter-spacing: 0.5rem;
    }
  }
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(
    to right,
    $color-primary-light,
    $color-primary-dark
  );
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;

  @include respond(tab-port) {
    font-size: 3rem;
  }

  @include respond(phone) {
    font-size: 2.5rem;
  }

  &--white {
    color: white;
  }

  &:hover {
    transform: skewY(2deg) skewX(15deg) scale(1.1);
    text-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
  }
}

.heading-tertiary {
  font-size: $default-font-size;
  font-weight: 700;
  text-transform: uppercase;
}

.paragraph {
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
