.week-plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8fafb;
  padding: 50px 0;
  // margin-bottom: 15rem;
  border-bottom: solid 2px $color-grey-light-2;

  // @media only screen and (max-width: $bp-small) {
  //   padding: 40px 20px;
  // }

  & > * {
    margin: 10px 0;
  }

  &__top {
    font-size: 20px;
    font-weight: bold;
    color: #2fade4;

    @media only screen and (max-width: $bp-small) {
      font-size: 14px;
      word-spacing: 3px;
    }
  }

  &__second {
    font-size: 30px;
    color: black;
    font-weight: bold;
    word-spacing: 0.8rem;

    @media only screen and (max-width: $bp-small) {
      font-size: 20px;
      word-spacing: 0.5rem;
      padding: 0 25px;
    }
  }

  &__third {
    font-size: 18px;
    width: 600px;
    line-height: 25px;

    @media only screen and (max-width: $bp-small) {
      font-size: 15px;
      width: 100%;
      line-height: 20px;
      padding: 5px 20px;
    }
  }

  &__card {
    width: 842px;
    background-color: white;
    box-shadow: $shadow-light;
    // padding: 20px;
    border-radius: 5px;
    margin-top: 20px;

    @media only screen and (max-width: $bp-medium) {
      width: 100%;
    }

    &__top {
      width: 168px;
      color: #fff;
      background-image: linear-gradient(
        to right bottom,
        rgba($color-primary-light, 0.8),
        rgba($color-primary-dark, 0.8)
      );
      font-size: 14px;
      padding: 10px 25px;
      display: flex;
      clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
    }

    &__main {
      display: flex;
      align-items: center;
      padding: 20px 50px;

      @media only screen and (max-width: $bp-small) {
        padding: 15px 20px;
      }
      &__left {
        height: 9px;
        width: 9px;
        border-radius: 9px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        background: #03aef8;
        margin-right: 30px;
        z-index: 100;

        @media only screen and (max-width: $bp-small) {
          margin-right: 10px;
        }
      }

      &__right {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        border-left: solid 2px $color-grey-light-2;

        @media only screen and (max-width: $bp-small) {
          padding-left: 20px;
        }
        &__title {
          font-size: 18px;
          color: black;
          font-weight: bold;
        }

        &__desc {
          font-size: 14px;
          margin-top: 20px;
        }

        &__show {
          color: #03aef8;
          font-size: 14px;
          margin-top: 18px;
          cursor: pointer;
        }

        // &__cards {

        // }

        &__card {
          padding: 10px 30px;
          display: flex;
          align-items: center;
          // height: 88px;
          background-color: white;
          border-radius: 10px;
          z-index: 1000;
          box-shadow: $shadow-light;
          margin-top: 20px;
          justify-content: space-between;

          @media only screen and (max-width: $bp-small) {
            padding: 10px;
          }

          & > * {
            margin: 0 10px;
          }

          &__info {
            display: flex;
            flex-direction: column;
            width: 60%;

            @media only screen and (max-width: $bp-small) {
              width: 65%;
            }

            & > * {
              margin: 3px 0;
            }

            &__title {
              color: black;
              font-weight: bold;
              font-size: 14px;
            }

            &__desc {
              font-size: 13px;
            }
          }

          &__right {
            color: #03aef8;
            padding: 5px 10px;
            border: solid 2px #03aef8;
            border-radius: 10px;
            display: flex;
          }
        }
      }
    }
  }
}
