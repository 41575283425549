.about {
  font-size: 18px;

  &__languages-offered {
    @include respond(tab-land) {
      width: 50vw;
      padding: 2px 20px;
    }
    @include respond(phone) {
      margin-top: 300px;
      width: 70%;
    }
  }

  &__section {
    //background-image: #f9e4b7;
    background-image: linear-gradient(
      to right bottom,
      rgba(#9cecfb, 0.8),
      rgba(#65c7f7, 0.8),
      rgba(#0052d4, 0.8)
    );
    margin-left: 200px;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    position: relative;
    @include respond(tab-land) {
      margin-bottom: 20px;
      margin-left: 20;
    }
  }

  &__image {
    position: absolute;
    top: 100px;
    right: -217px;
    @include respond(big-desktop) {
      right: -36px;
    }
    @include respond(tab-land) {
      display: none;
    }
  }

  &__main-image {
    position: relative;
    @include respond(tab-land) {
      margin-bottom: 250px;
    }
  }
  &__main-text {
    height: 50vh;
    width: 35vw;
    padding: 0 80px;
    position: absolute;
    bottom: 8px;
    left: 250px;
    background-color: $color-grey-dark-3;

    @include respond(big-desktop) {
      padding: 0 80px;
      height: 60vh;
      width: 20vw;
      margin-left: 200px;
    }
    @include respond(tab-land) {
      position: absolute;
      width: 50%;
      top: 0;
      left: 14%;
      padding: 0 auto;
      height: 55vh;
      width: 72vw;
    }

    @include respond(phone) {
      width: 100%;
      padding: 0 20px;
      height: 65vh;
      position: absolute;
      left: 0px;
      top: 0;
    }
  }

  &__section {
    position: relative;
    height: 70vh;
    width: 70vw;
    padding: 0 100px;
    @include respond(tab-land) {
      display: none;
    }
    @include respond(phone) {
      display: none;
    }
  }

  &__secondary-text {
    height: 45vh;
    width: 30vw;
    padding: 0 80px;
    background-color: $color-grey-dark-3;
    position: absolute;
    top: 50px;

    @include respond(big-desktop) {
      width: 20vw;
      margin-left: 500px;
    }

    @include respond(tab-land) {
      width: 45vw;
    }
  }
}
