@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.isa_info,
.isa_success,
.isa_warning,
.isa_error {
  margin: 10px 0px;
  padding: 12px;
  font-size: 16px;
}
.isa_info {
  color: #00529b;
  background-color: #bde5f8;
}
.isa_success {
  color: #4f8a10;
  background-color: #dff2bf;
}
.isa_warning {
  color: #9f6000;
  background-color: #feefb3;
}
.isa_error {
  color: #d8000c;
  background-color: #ffd2d2;
}
.isa_info i,
.isa_success i,
.isa_warning i,
.isa_error i {
  margin: 10px 22px;
  font-size: 2em;
  vertical-align: middle;
}
