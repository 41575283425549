.week-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8fafb;
  padding: 50px;
  border-bottom: solid 2px $color-grey-light-2;

  @media only screen and (max-width: $bp-small) {
    padding: 40px 20px;
  }

  & > * {
    margin: 10px 0;
  }

  &__top {
    font-size: 20px;
    font-weight: bold;
    color: #2fade4;

    @media only screen and (max-width: $bp-small) {
      font-size: 14px;
      word-spacing: 3px;
    }
  }

  &__second {
    font-size: 30px;
    color: black;
    font-weight: bold;
    word-spacing: 0.8rem;
    @media only screen and (max-width: $bp-small) {
      font-size: 20px;
      word-spacing: 0.5rem;
    }
  }

  &__third {
    font-size: 18px;
    width: 700px;
    line-height: 25px;

    @media only screen and (max-width: $bp-small) {
      font-size: 15px;
      width: 100%;
      line-height: 20px;
      padding: 5px;
    }
  }

  &__videos {
    display: flex;
    justify-content: center;
    max-width: 1440px;
    flex-wrap: wrap;

    & > * {
      margin: 20px 50px;
    }

    &__video-box {
      display: flex;
      flex-direction: column;
      width: 345px;

      & > * {
        margin: 10px 0;
      }
    }

    &__video {
      height: 210px;
      // height: 100%;
      width: 345px;
      border: solid 5px whitesmoke;
      border-radius: 10px;

      &__title {
        font-size: 22px;
        color: black;
        font-weight: bold;
      }

      &__desc {
        font-size: 14px;
      }
    }
  }
}
